import React from "react";
import {
  UploadAdapter,
  FileLoader,
} from "@ckeditor/ckeditor5-upload/src/filerepository";

import "./editor.css";
import { IEditor } from "./editor.types";
import EditorComponent from "./editor.component";
import { useUploadFileMutation } from "src/services/auth";

const EditorContainer = (props: IEditor) => {
  const [uploadFile] = useUploadFileMutation();

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const res = await uploadFile({ image: file }).unwrap();
            resolve({
              default: res?.data?.image_name,
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => {},
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return uploadAdapter(loader);
    };
  }

  return <EditorComponent {...props} uploadPlugin={uploadPlugin} />;
};

export default EditorContainer;
